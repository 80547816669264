export const spacing2 = '0.125rem' as const // 2px
export const spacing4 = '0.25rem' as const // 4px
export const spacing6 = '0.375rem' as const // 6px
export const spacing8 = '0.5rem' as const // 8px
export const spacing10 = '0.625rem' as const // 10px
export const spacing12 = '0.75rem' as const // 12px
export const spacing16 = '1rem' as const // 16px
export const spacing20 = '1.25rem' as const // 20px
export const spacing24 = '1.5rem' as const // 24px
export const spacing32 = '2rem' as const // 32px
export const spacing40 = '2.5rem' as const // 40px
export const spacing48 = '3rem' as const // 48px
export const spacing60 = '3.75rem' as const // 60px
export const spacing68 = '4.25rem' as const // 68px
export const spacing80 = '5rem' as const // 80px
export const spacing120 = '7.5rem' as const // 120px
export const spacingAuto = 'auto' as const // auto
