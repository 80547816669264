// gray scale
export const C_BLACK = '#000000'
export const C_DARK_GRAY = '#4a4a4a'
export const C_MED_DARK_GRAY = '#6c6c6c'
export const C_MED_GRAY = '#9b9b9b'
export const C_SILVER_GRAY = '#e3e3e3'
export const C_MED_LIGHT_GRAY = '#d2d2d2'
export const C_LIGHT_GRAY = '#e6e6e6'
export const C_BRIGHT_GRAY = '#f8f8f8'
export const C_HARBOR_GRAY = '#8a8c8e'
export const C_NEAR_WHITE = '#fafafa'
export const C_WHITE = '#ffffff'
export const C_TRANSPARENT = 'transparent'
export const C_DARK_BLACK = '#16212d'

// brand colors
export const C_BLUE = '#006cfa'
export const C_MINT = '#00bfa8'
export const C_AQUAMARINE = '#eefff8'
export const C_POWDER_BLUE = '#f1f8ff'
export const C_SKY_BLUE = '#accfff80'
export const C_BLUE_PRESSED = '#0050b8'

// colors by usage
// TODO(mc, 2020-10-08): s/COLOR_/C_
export const COLOR_WARNING = '#e28200'
export const COLOR_WARNING_LIGHT = '#ffd58f'
export const COLOR_WARNING_DARK = '#9e5e00'
export const COLOR_ERROR = '#d12929'
export const COLOR_SUCCESS = '#60b120'
export const C_DISABLED = '#9c9c9c'
export const C_SELECTED_DARK = '#00c3e6'
export const C_BG_SELECTED = '#f0f3ff'
export const C_ERROR_DARK = '#ac0505'
export const C_ERROR_LIGHT = '#ffc0c0'

// overlays
export const OVERLAY_WHITE_10 = 'rgba(255, 255, 255, 0.1)'
export const OVERLAY_WHITE_20 = 'rgba(255, 255, 255, 0.2)'

// used to set aside further subsections of cards beyond
// what can be distinguished with a border
export const OVERLAY_LIGHT_GRAY_50 = 'rgba(230, 230, 230, 0.4)'

// used as default BaseModal overlay color
export const OVERLAY_GRAY_90 = 'rgba(127, 127, 127, 0.9)'

// darkest modal overlay used
export const OVERLAY_BLACK_90 = 'rgba(0, 0, 0, 0.9)'

// 80 percent opacity overlay
export const OVERLAY_BLACK_80 = 'rgba(0, 0, 0, 0.8)'

// opacities
export const OPACITY_DISABLED = 0.3

// TODO(isk: 3/2/20): Rename to be more generic (e.g. not FONT)
export const C_FONT_DISABLED = '#9c9c9c'
